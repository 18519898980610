<template>
  <v-row class="mt-1" no-gutters>
    <v-row no-gutters>
      <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" class="pt-0">
        <v-row align="center" justify="center" no-gutters>
          <v-chip
            :color="vehicleStatus.color"
            small
            class="white--text mt-1 ml-1"
            >{{ vehicleStatus.status }}
          </v-chip>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-1">
        <v-row
          v-if="vehiculo.datos.unicoDueno === 'Sí'"
          align="center"
          justify="space-between"
          no-gutters
        >
          <v-row align="center" no-gutters>
            <v-icon class="mr-3" color="info">mdi-account</v-icon>
            <h3>Dueño Unico</h3>
          </v-row>
          <h3
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'mr-3 text-capitalize'
                : 'mr-6 text-capitalize'
            "
            class="font-weight-regular"
          >
            {{ vehiculo.datos.unicoDueno }}
          </h3>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-1">
        <v-row align="center" justify="space-between" no-gutters>
          <v-row align="center" no-gutters>
            <v-icon class="mr-3" color="info">fas fa-tachometer-alt</v-icon>
            <h3>Kilometraje</h3>
          </v-row>
          <h3
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'mr-3 text-capitalize'
                : 'mr-6 text-capitalize'
            "
            class="font-weight-regular"
          >
            {{ kilometrajeFix(vehiculo.datos.Kilometraje) }}
          </h3>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-1">
        <v-row align="center" justify="space-between" no-gutters>
          <v-row align="center" no-gutters>
            <v-icon class="mr-3" color="info">mdi-car-shift-pattern</v-icon>
            <h3>Transmisión</h3>
          </v-row>

          <h3
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'mr-3 text-capitalize'
                : 'mr-6 text-capitalize'
            "
            class="font-weight-regular"
          >
            {{ vehiculo.datos.Transmision }}
          </h3>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-1">
        <v-row align="center" justify="space-between" no-gutters>
          <v-row align="center" no-gutters>
            <v-icon class="mr-3" color="info">mdi-car-traction-control</v-icon>
            <h3>Tracción</h3>
          </v-row>

          <h3
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'mr-3 text-capitalize'
                : 'mr-6 text-capitalize'
            "
            class="font-weight-regular"
          >
            {{ vehiculo.datos.traccion }}
          </h3>
        </v-row>
      </v-col>
      <v-col v-if="vehiculo.datos.Categoria.length" cols="12" class="mb-1">
        <v-row align="center" justify="space-between" no-gutters>
          <v-row align="center" no-gutters>
            <v-icon class="mr-3" color="info">mdi-car-outline</v-icon>
            <h3>Carroceria</h3>
          </v-row>

          <h3
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'mr-3 text-capitalize'
                : 'mr-6 text-capitalize'
            "
            class="font-weight-regular"
          >
            {{ vehiculo.datos.Categoria[0] }}
          </h3>
        </v-row>
      </v-col>
      <v-col v-if="vehiculo.datos.doors" cols="12" class="mb-1">
        <v-row align="center" justify="space-between" no-gutters>
          <v-row align="center" no-gutters>
            <v-icon class="mr-3" color="info">mdi-car-door</v-icon>
            <h3>Nº de puertas</h3>
          </v-row>

          <h3
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'mr-3 text-capitalize'
                : 'mr-6 text-capitalize'
            "
            class="font-weight-regular"
          >
            {{ vehiculo.datos.doors[0] }}
          </h3>
        </v-row>
      </v-col>
      <v-col v-if="vehiculo.datos.speed" cols="12" class="mb-1">
        <v-row align="center" justify="space-between" no-gutters>
          <v-row align="center" no-gutters>
            <v-icon class="mr-3" color="info">mdi-speedometer</v-icon>
            <h3>Nº de velocidades</h3>
          </v-row>

          <h3
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'mr-3 text-capitalize'
                : 'mr-6 text-capitalize'
            "
            class="font-weight-regular"
          >
            {{ vehiculo.datos.speed[0] }}
          </h3>
        </v-row>
      </v-col>
      <v-col v-if="vehiculo.datos.power" cols="12" class="mb-1">
        <v-row align="center" justify="space-between" no-gutters>
          <v-row align="center" no-gutters>
            <v-icon class="mr-3" color="info">mdi-car-speed-limiter</v-icon>
            <h3>Potencia</h3>
          </v-row>

          <h3
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'mr-3 text-capitalize'
                : 'mr-6 text-capitalize'
            "
            class="font-weight-regular"
          >
            {{ vehiculo.datos.power }}
          </h3>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-1">
        <v-row align="center" justify="space-between" no-gutters>
          <v-row align="center" no-gutters>
            <v-icon class="mr-3" color="info">mdi-gas-station</v-icon>
            <h3>Combustible</h3>
          </v-row>

          <h3
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'mr-3 text-capitalize'
                : 'mr-6 text-capitalize'
            "
            class="font-weight-regular"
          >
            {{ vehiculo.datos.Combustible }}
          </h3>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-1">
        <v-row align="center" justify="space-between" no-gutters>
          <v-row align="center" no-gutters>
            <v-icon class="mr-3" color="info">mdi-palette</v-icon>
            <h3>Color</h3>
          </v-row>

          <h3
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'mr-3 text-capitalize'
                : 'mr-6 text-capitalize'
            "
            class="font-weight-regular"
          >
            {{ vehiculo.datos.Color }}
          </h3>
        </v-row>
      </v-col>
      <v-col v-if="files.length" cols="12" class="mb-1">
        <h3 class="pl-2">Documentos del vehículo</h3>
        <v-row align="center" justify="center" no-gutters>
          <v-chip
            v-for="(file, key, index) in files"
            :key="index"
            :color="'info'"
            class="white--text mt-1 ml-1"
            @click="openLink(file.url)"
          >
            {{ file.label }}
            <v-icon>mdi-eye</v-icon>
          </v-chip>
        </v-row>
      </v-col>
      <v-col cols="12" :class="$vuetify.breakpoint.mdAndUp ? 'my-3' : ''">
        <h3 class="pl-2">
          Vehículo con garantía y servicio de asistencia en ruta
        </h3>
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.mdAndUp" cols="12">
        <h1
          class="text-center mt-6"
          :class="!$vuetify.breakpoint.mdAndUp ? 'my-2' : ''"
        >
          Valor {{ precioFix(vehiculo.datos.Precio_de_Venta) }}
        </h1>
      </v-col>
      <v-divider class="mx-2"></v-divider>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12">
        <h1
          class="text-center mt-6"
          :class="!$vuetify.breakpoint.mdAndUp ? 'mb-12' : ''"
        >
          Valor {{ precioFix(vehiculo.datos.Precio_de_Venta) }}
        </h1>
      </v-col>
      <v-col v-if="status" cols="12">
        <v-row
          align="center"
          justify="center"
          no-gutters
          @click.stop
          class="mt-2 pb-2"
        >
          <v-menu :nudge-left="80" offset-y top open-on-hover>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                color="info"
                :disabled="availableStatus(vehicleStatus.status)"
                v-bind="attrs"
                v-on="on"
              >
                Me Interesa
              </v-btn>
            </template>

            <v-card class="pa-2" width="300" color="white">
              <v-btn
                color="info"
                :disabled="availableStatus(vehicleStatus.status)"
                :small="$vuetify.breakpoint.smAndDown"
                block
                @click="openContact(vehiculo)"
                class="py-1 my-1"
              >
                Que me contacten
              </v-btn>
              <v-btn
                color="info"
                :disabled="availableStatus(vehicleStatus.status)"
                :small="$vuetify.breakpoint.smAndDown"
                block
                class="py-1 my-1"
                @click="openFinancials(vehiculo)"
              >
                Financiamiento
              </v-btn>
              <v-btn
                color="info"
                :disabled="availableStatus(vehicleStatus.status)"
                :small="$vuetify.breakpoint.smAndDown"
                block
                class="py-1 my-1"
                @click="openRenew(vehiculo)"
              >
                Renovación
              </v-btn>
            </v-card>
          </v-menu>
        </v-row>
      </v-col>
      <v-col v-if="basic && basic.length" cols="12">
        <h2 class="text-center mt-6">Equipamiento basico</h2>
      </v-col>
      <v-col v-if="basic && basic.length" cols="12" class="pa-0">
        <h3
          v-for="(equipment, i) in basic"
          :key="i"
          class="text-start text-capitalize mt-6 ml-4"
        >
          <ul>
            <li class="font-weight-regular">
              {{ equipment }}
            </li>
          </ul>
        </h3>
      </v-col>
      <v-col v-if="comfort && comfort.length" cols="12">
        <h2 class="text-center mt-6">Equipamiento comfort</h2>
      </v-col>
      <v-col v-if="comfort && comfort.length" cols="12" class="pa-0">
        <h3
          v-for="(equipment, i) in comfort"
          :key="i"
          class="text-start text-capitalize mt-6 ml-4"
        >
          <ul>
            <li class="font-weight-regular">
              {{ equipment }}
            </li>
          </ul>
        </h3>
      </v-col>
      <v-col v-if="security && security.length" cols="12">
        <h2 class="text-center mt-6">Equipamiento seguridad</h2>
      </v-col>
      <v-col
        v-if="security && security.length"
        cols="12"
        class="pa-0"
        :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : ''"
      >
        <h3
          v-for="(equipment, i) in security"
          :key="i"
          class="text-start text-capitalize mt-6 ml-4"
        >
          <ul>
            <li class="font-weight-regular">
              {{ equipment }}
            </li>
          </ul>
        </h3>
      </v-col>
      <v-col v-if="external && external.length" cols="12">
        <h2 class="text-center mt-6">Equipamiento exterior</h2>
      </v-col>
      <v-col v-if="external && external.length" cols="12" class="pa-0">
        <h3
          v-for="(equipment, i) in external"
          :key="i"
          class="text-start text-capitalize mt-6 ml-4"
        >
          <ul>
            <li class="font-weight-regular">
              {{ equipment }}
            </li>
          </ul>
        </h3>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import {kilometrajeFix, precioFix} from '@/utils/vehiculo.js';
import {mapActions} from 'vuex';

export default {
  name: 'vehiculoInfo',
  components: {},
  data: () => ({
    video: null,
    files: [],
  }),
  props: {
    vehiculo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fixHeight() {
      return `${this.$vuetify.breakpoint.height - 50}px`;
    },
    status() {
      const {vehiculo} = this;
      if (!vehiculo || !vehiculo.datos || !vehiculo.datos.status) return false;
      return 'Disponible'.includes(vehiculo.datos.status);
    },
    vehicleStatus() {
      const {vehiculo} = this;

      const color = this.getColor(vehiculo.datos.status);

      return {
        status: vehiculo.datos.status,
        color,
      };
    },
    comfort() {
      const {vehiculo} = this;
      return vehiculo.datos.equipamiento_comfort
        ? vehiculo.datos.equipamiento_comfort
        : [];
    },
    security() {
      const {vehiculo} = this;

      return vehiculo.datos.equipamiento_seguridad
        ? vehiculo.datos.equipamiento_seguridad
        : [];
    },
    external() {
      const {vehiculo} = this;

      return vehiculo.datos.equipamiento_exterior
        ? vehiculo.datos.equipamiento_exterior
        : [];
    },
    basic() {
      const {vehiculo} = this;

      return vehiculo.datos.equipamiento_basico
        ? vehiculo.datos.equipamiento_basico
        : [];
    },
  },
  methods: {
    ...mapActions(['findAirtableVehicle']),
    getValues(array) {
      return array.join(', ');
    },
    availableStatus(status) {
      return status === 'RESERVADO' || status === 'VENTA EN PROCESO';
    },
    openFinancials(vehiculo) {
      this.$router.push({
        name: 'financiamiento',
        query: {vehicle: vehiculo.id},
      });
    },
    openRenew(vehiculo) {
      this.$router.push({
        name: 'renovacion',
        query: {vehicle: vehiculo.id},
      });
    },
    openContact(vehiculo) {
      this.$router.push({
        name: 'contacto',
        query: {vehicle: vehiculo.id},
      });
    },
    precioFix,
    kilometrajeFix,
    message(val) {
      let item = `https://wa.me/56944436444?text=Hola%20Petersen,%20vi%20en%20su%20web%20este%20${
        val.datos.titulo.split('|')[0]
      }%20y%20me%20gustaría%20tener%20mas%20información`;
      window.open(item, '_blank');
    },
    getColor(icon) {
      if (icon.toLowerCase().includes('reservado')) {
        return 'purple';
      }

      if (icon.toLowerCase().includes('venta')) {
        return 'info';
      }

      return 'green';
    },
    openLink(url) {
      window.open(url, '_blank');
    },
    mapFiles() {
      const availableFiles = {
        circulationPermit: 'Permiso de circulación',
        soap: 'SOAP',
        technicalReview: 'Revisión Técnica',
        cav: 'CAV',
        report: 'Informe Legal',
        penalties: 'Multas',
      };

      this.files = Object.keys(availableFiles).reduce((acc, key) => {
        if (this.$route.query[key]) {
          acc.push({
            label: availableFiles[key],
            url: this.$route.query[key],
          });
        }
        return acc;
      }, []);
    },
  },
  mounted() {
    this.findAirtableVehicle(this.vehiculo.id);
    this.mapFiles();
  },
};
</script>
